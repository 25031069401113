.cards-list-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* flex-direction: column; */
}

.applepay-logo {
  margin-top: 100px;
}

.generate-card {
  font-size: 46px;
  margin-bottom: 24px;
  font-weight: 900;
}

.card {
  position: relative;
  border-radius: 16px;
  box-shadow: 0 1px 1px rgb(40 51 75 / 20%);
  box-sizing: border-box;
  background-color: white;
  /* Add your logo as a background image */
  /* background-image: url("https://lithic.com/sterling/img/logo-black.4793a8fe.svg"); */
  background-repeat: no-repeat;
  /* background-position will need adjusting depending on your logo */
  background-position: bottom 150px left 190px;
  color: black;
  display: flex;
  flex-direction: column;
  /* font-family: "Roboto Mono", sans-serif; */
  font-size: 16px;
  height: 180px;
  justify-content: space-between;
  line-height: 20px;
  /* margin-top: 40px; */
  margin-right: 16px;
  overflow: hidden;
  padding: 20px;
  position: relative;
  user-select: none;
  width: 288px;
  cursor: pointer;
}

.selected .card {
  box-shadow: 0 10px 16px rgb(0 0 0 / 82%);
  border: 2px solid #e500ff;
}

/* FUCKING CURRENCY DROP DOWN */
.ant-select-dropdown {
  color: black !important;
  background-color: gray;
}

.ant-select-item-option-selected {
  color: black !important;
  background-color: magenta !important;
}

.ant-select-item-option-active {
  color: black !important;
  background-color: black !important;
}

.black-card {
  background-color: black;
  padding: 0px;
  margin: 0px;
  border: 0px;
  overflow: hidden;
  overflow-x: hidden;
}

.black-card.selected {
  border: 0px solid white;
}

.card:hover {
  /*opacity: .7;*/
  border-color: magenta;
}

.card__bottom {
  display: flex;
  justify-content: space-between;
}

.card__top {
  display: flex;
  justify-content: space-between;
}

.pan-separator {
  margin: 6px;
}

/* .pan {
  border-radius: 6px;
  bottom: 65px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 500;
  height: 32px;
  justify-content: center;
  left: 14px;
  letter-spacing: 6px;
  line-height: 30px;
  padding: 2px 10px 0;
  position: absolute;
} */


.expiry {
  border-radius: 6px;
  bottom: 24px;
  font-size: 16;
  font-weight: 400;
  left: 20px;
  line-height: 30px;
  opacity: 0.8;
  padding: 2px 4px 0;
  position: absolute;
  padding-top: 5px;
}

.month,
.year {
  border-radius: 6px;
  cursor: pointer;
  line-height: 30px;
  padding: 4px 1px;
}

.cvv {
  border-radius: 6px;
  bottom: 24px;
  cursor: pointer;
  font-size: 16;
  font-weight: 400;
  left: 92px;
  line-height: 30px;
  margin-left: 25px;
  opacity: 0.8;
  padding: 2px 4px 0;
  position: absolute;
  padding-top: 5px;
}

.expiry::before {
  content: 'EXP ';
}

.cvv::before {
  content: 'CVV ';
}

.cvv:hover,
.pan:hover,
.month:hover,
.year:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.cvv:active,
.pan:active,
.month:active,
.year:active {
  background-color: rgba(0, 0, 0, 0.05);
}

.alert {
  display: none;
}


.new-card-form {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 343px;
}


.enroll-field {
  margin-bottom: 1rem;
  color: white;
}

.enroll-field input {
  width: 260px;
  color: #fff;
}



.card-title-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.card-list-left {
  width: 296px;
  margin-bottom: 24px;
}


.transactions-container {
  width: 65%;
}

.transactions-table {
  margin-top: 40px;
  border-top: 2px solid #F2F6FA;
  width: 100%;
  font-size: 18px;
}

.transactions-table td {
  padding-top: 16px;
}

.credit-card {
  margin-right: 8px;
}

.bold {
  font-weight: bold;
}

.status {
  color: #CAD1D5;
}


.edit-card {
  cursor: pointer;
}

.add-new-card {
  cursor: pointer;
  /* color: #CAD1D5; */
}

.p-button {
  font-size: 20px;
  font-weight: bold;
  padding: 8px;
  width: 200px;
  border: 3px solid #9908DD;
  border-radius: 4px;
  margin-top: 36px;
  display: block;
  text-align: left;
  /*background-color: white;*/
  padding-left: 18px;
  cursor: pointer;
  text-align: center;
  margin-left: 24px;
}

.button-list {
  display: flex;
  justify-content: space-between;
}

.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 36px;
}


.money {
  width: 175px;
}

.dex-table {
  color: gray;
  font-size: 20px;
  margin-top: 40px;
  width: 100%;
}

.dex-table td {
  padding-right: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid gray;
}


.card-container-iframe {
  display: flex;
  margin-left: 4px;

}



.flip-card {
  /* background-color: transparent; */
  /* border: 1px solid #f1f1f1; */
  margin-top: 30px;
  height: 180px;
  width: 288px;
  perspective: 1000px;
  /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
/* .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  } */

.flip-card-inner.flipping {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;

  -webkit-backface-visibility: hidden;
  /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: black;
  color: magenta;
}

/* Style the back side */
.flip-card-back {

  width: 100%;
  transform: rotateY(180deg);
}

.sliderView {
  margin-bottom: 24px;
  display: flex;
}

.MuiBox-root .sliderView {
  display: none;
}

.invisible-card {
  position: absolute;
  width: 278px;
  height: 66px;
  background-color: transparent;
  z-index: 99;
  cursor: pointer;
  border-radius: 8px;
  left: 2px;
  opacity: 0;
}

.MuiInputLabel-asterisk {
  color: red;
}

.card-scroller {
  max-height: 680px;
  width: 325px;
  /* overflow: auto; */
}

.status-drop {
  margin-bottom: 16px;
  width: 278px;
}


.asset-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}

.trans-summary {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;
  flex-wrap: wrap;
  color: white;
}

.asset-container .ant-select-selector {
  min-width: 300px !important;
  color: #fff;
  border-radius: 20px !important;
  border: 2px solid #e500ff !important;
  height: 74px !important;
  padding: 8px 20px !important;
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.ant-typography {
  color: white !important;
  font-size: 1.2em !important;
}


.asset-item-summary {
  margin-top: 16px;
  margin-left: auto;
  color: white;
}

.summary-item-title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 16px;
  color: white !important;
}

.enroll-field {
  color: black
}

.summary-item-value {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 16px;
  color: white !important;
}

.MuiOutlinedInput-root MuiInputBase-root MuiInputBase-colorPrimary MuiInputBase-formControl css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  color: white !important;
}

@media screen and (max-width: 600px) {
  .contain-all-cards {
    display: flex;
    overflow: auto;
  }

  .transactions-container {
    width: 100%;
  }

  .card-list-left {
    width: 100%;
  }

  .tran-date,
  .status,
  .tran-location {
    display: none;
  }
}

.enroll-field .MuiOutlinedInput-notchedOutline {
  border: 2px solid #e500ff !important;
  /*background-color: whitesmoke;*/
  background-color: rgba(255, 255, 255, 0.2);
  height: 4em;
  color: #fff;
}

.new-card-form .MuiOutlinedInput-notchedOutline {
  border: 2px solid #e500ff !important;
  color: #fff;
}