.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected::after {
  border-bottom: 2px solid #21bf96 !important;
}

html {
  /* this makes sure the padding and the border is included in the box sizing */
  box-sizing: border-box;
  overflow-x: hidden;
}

body {
  color: #fff;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  height: auto;
}

@media screen and (min-width: 991px) {
  body {
    background-attachment: fixed;
    width: "30%";
    float: "right";
    margin-left: "350px";
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    height: auto;
  }
}

@media screen and (max-width: 991px) {
  .dashboard {
    margin-left: "100px" !important;
  }
}

@media screen and (min-width: 991px) {
  .dashboard {
    margin-left: 340px;
  }
}

.ant-menu-horizontal>.ant-menu-item-selected a,
.ant-menu-horizontal>.ant-menu-item a:hover {
  color: magenta !important;
}

.ant-modal-content {
  overflow: auto;
  border-radius: 1rem;
}

.jw-logo {
  display: flex;
  margin-left: 20px;
  margin-top: 25px;
  margin-bottom: 25px;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  color: white !important;
}

MuiButton {
  color: white;
  background-color: rgba(255, 255, 255, 0.2);
}

.ant.select.selector {
  color: black;
}

.btn-gen-mastercard {
  letter-spacing: .2rem;
  font-family: Roboto !important;
  font-weight: 400;
  align-items: center;
  vertical-align: center;
}

@media screen and (max-width: 991px) {
  .jw-logo {
    display: none;
  }
}

.ant-menu-item a {
  font-family: "ROBOTO";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  text-transform: uppercase;
  color: white;
}

.ant-menu {
  background-color: transparent;
  border: none;
}

.ant-menu-item-selected {
  background-color: #616366 !important;
}

.ant-menu-item-active {
  background-color: #616366 !important;
}

.ant-menu-item-active a {
  color: white !important;
}

.ant-menu-item-selected::after {
  display: none;
}

/* .ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 0;
  left: 0;
  right: -36px;
  z-index: 1;
  width: 100vw;
  height: 42px;
  color: #fff;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
  background: #001529;
  border-radius: 0 2px 2px 0;
  cursor: pointer;
  transition: background 0.3s ease;
  height: 98px;
  background: linear-gradient(316.59deg, #2a14f6 1.1%, #e409f9 98.47%);
} */

.ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: -59px;
  right: auto;
  z-index: 1;
  width: 55px;
  height: 42px;
  color: #fff;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
  border-radius: 0 2px 2px 0;
  cursor: pointer;
  transition: background 0.3s ease;
  background-color: transparent;
  overflow-x: hidden;
  /* background-image: url(./jw_logo_2022_site.svg); */
}

/*MOBILE VIEW PORT*/
@media screen and (max-width: 991px) {
  .ant-layout-sider {
    position: fixed;
  }

  .ant-layout-sider {
    top: 100px !important;
  }

  .mobile-header {
    height: 101px;
    width: 100vw;
    /* background: linear-gradient(316.59deg, #2a14f6 1.1%, #e409f9 98.47%); */
    background: linear-gradient(316.59deg, #1c2025 1.1%, #1f1f1f 98.47%);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: sticky;
    top: 0;
    z-index: 3;
  }

  .mobile-header-logo {
    width: 261px;
    height: 75px;
  }
}

/*MOBILE VIEW PORT*/
@media screen and (max-width: 991px) {
  .siderr {
    background-image: url(./rectangle.svg) !important;
    fill: #1e2024;
    background-repeat: no-repeat;
    background-color: transparent !important;
    background-color: #1e2024 !important;
    background-size: cover;
    height: "100vh";
    min-height: 100%;
    background-color: none !important;

  }
}

/*DESKTOP VIEW PORT*/
@media screen and (min-width: 991px) {
  .siderr {
    background-image: url(./rectangle.svg) !important;
    fill: #1e2024 !important;
    background-repeat: no-repeat;
    background-color: transparent !important;
    background-size: cover;
    height: "100vh";
    min-height: 100%;
    background-color: none !important;
    background-color: #1e2024 !important;
    position: fixed;
  }
}



/*MOBILE VIEW PORT*/
@media screen and (min-width: 991px) {
  .ant-layout-header {
    background-color: transparent !important;
    min-Height: "100%";
    margin-right: "350px";
    height: "100vh" !important;
    background-color: #1e2024 !important;
    position: relative;
  }
}

/*MOBILE VIEW PORT*/
@media screen and (max-width: 991px) {
  .ant-layout-header {
    background-color: #1e2024 !important;
  }
}

.ant-layout-sider {
  min-width: 382px !important;
  flex: 0 0 320px !important;
  max-width: 320px !important;
  width: 320px !important;
  background-color: transparent !important;
}

.ant-layout-sider-collapsed {
  min-width: 0 !important;
  flex: 0 0 0px !important;
  max-width: 0px !important;
  width: 0px !important;
}

.generate-card {
  font-size: 46px;
  margin-bottom: 24px;
  font-weight: 900;
  color: #fff
}

.h2 {
  font-size: 26px;
  margin-bottom: 24px;
  font-weight: 900;
  color: #ffffff
}

.h3 {
  font-size: 22px;
  margin-bottom: 24px;
  font-weight: 900;
  color: #ffffff
}

.h4 {
  font-size: 18px;
  margin-bottom: 24px;
  font-weight: 900;
  color: #ffffff
}

.ant-card-bordered {
  border-color: white;
}

.ant-btn-primary {
  color: magenta !important;
  border-color: magenta !important;
  border-width: 3px;
  background: #ffffff !important;
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.ant-btn-primary:hover {
  color: white !important;
  border-color: magenta !important;
  border-width: 3px;
  background: magenta !important;
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.ant-btn-primary:active {
  color: white !important;
  border-color: magenta !important;
  border-width: 3px;
  background: purple !important;
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}


.summary-item-title {
  color: black !important;
}

.summary-item-value {
  color: black !important;
}


.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  color: white
}

.ant-card-bordered {
  color: magenta;
}

a {
  color: #ff00ff;
}

a:link {
  color: magenta;
}

a:visited {
  color: magenta;
}

a:hover {
  color: pink;
}

a:active {
  color: magenta;
}

.disconnectwalletmodal {
  color: black;
}

/* a {
    color: #ff00ff;
  } */

a:link {
  color: magenta;
}

a:visited {
  color: magenta;
}

a:hover {
  color: pink;
}

a:active {
  color: magenta;
}

@media screen and (max-width: 991px) {
  .page-width {
    width: 65vw;
  }
}

@media screen and (min-width: 991px) {
  .page-width {
    width: 65vw;
  }
}

@media screen and (max-width: 991px) {
  .gencard-modal {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 80vw;
    height: 60vh;
    padding: 10px;
    padding-left: 20px;
    margin-right: 0px;
    display: inline-block;
    vertical-align: -webkit-baseline-middle;
    background: rgba(255, 255, 255, 0.15);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(8.5px);
    -webkit-backdrop-filter: blur(8.5px);
    border-radius: 10px;
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 0.2rem !important;
  }
}

.gencard-modal {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 60vw;
  height: 80vh;
  padding: 10px;
  padding-left: 20px;
  margin-right: 0px;
  display: inline-block;
  vertical-align: -webkit-baseline-middle;
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
  border-radius: 10px;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.2rem !important;
}

/* @media screen and (max-width: 600px) {
  .setcard-amount-modal {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    height: 60vh;
    padding: 10px;
    padding-left: 30px;
    margin-right: 20px;
    display: inline-block;
    vertical-align: -webkit-baseline-middle;
    background: rgba(255, 255, 255, 0.15);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(8.5px);
    -webkit-backdrop-filter: blur(8.5px);
    border-radius: 10px;
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 0.2rem !important;
  }
} */

.setcard-amount-modal {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 380px;
  height: 735px;
  padding: 10px;
  padding-left: 30px;
  margin-right: 20px;
  display: inline-block;
  vertical-align: -webkit-baseline-middle;
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
  border-radius: 10px;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.2rem !important;
}

/* @media screen and (max-width: 600px) {
  .update-card-modal {
    display: flex;
    background-color: #fff;
    align-items: center;
    flex-direction: column;
    width: 20vw;
    height: 40vh;
    padding: 10px;
    padding-left: 30px;
    margin-right: 20px;
    display: inline-block;
    vertical-align: -webkit-baseline-middle;
    background: rgba(255, 255, 255, 0.15);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(8.5px);
    -webkit-backdrop-filter: blur(8.5px);
    border-radius: 10px;
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 0.2rem !important;
  }
} */

.update-card-modal {
  display: flex;
  background-color: #fff;
  align-items: center;
  flex-direction: column;
  width: 380px;
  height: 320px;
  padding: 10px;
  padding-left: 30px;
  margin-right: 20px;
  display: inline-block;
  vertical-align: -webkit-baseline-middle;
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
  border-radius: 10px;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.2rem !important;
}

.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  color: white !important;
}

.css-m9bhtb {
  padding: 0em !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  max-width: 100%;
  border: 0px solid rgb(0, 0, 0);
  box-shadow: rgb(0 0 0 / 0%) 0px 11px 15px -7px, rgb(0 0 0 / 0%) 0px 24px 38px 3px, rgb(0 0 0 / 0%) 0px 9px 46px 8px;
  border-radius: 16px;
  padding: 32px;
  background-color: gray;
}

.css-1wc848c-MuiFormHelperText-root {
  color: white !important;
}

.css-10drtbx-MuiButtonBase-root-MuiCheckbox-root {
  color: white !important;
}

.css-u9osun {
  color: white !important;
}

.css-u9osun-focused {
  color: white !important;
}

ant-layout-sider ant-layout-sider-dark siderr {
  position: fixed;
}

ant-layout-sider-children {
  position: fixed;
}

.css-9npbnl-MuiFormLabel-root-MuiInputLabel-root {
  color: white !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  color: white !important;

}

.MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeMedium MuiButton-outlinedSizeMedium MuiButtonBase-root css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
  color: white !important;

}

.css-109o0il {
  color: white !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0px;
  max-width: 390px !important;
  border: 0px solid rgb(0, 0, 0) !important;
  box-shadow: rgb(0 0 0 / 0%) 0px 11px 15px -7px, rgb(0 0 0 / 0%) 0px 24px 38px 3px, rgb(0 0 0 / 0%) 0px 9px 46px 8px !important;
  border-radius: 16px;
  padding: 0px !important;
}

.css-13maa2j {
  color: white !important;
}

.css-qiwgdb.css-qiwgdb.css-qiwgdb {
  padding-right: 32px;
  color: white !important;
}

.css-79xub {
  border-color: magenta !important;
}