*,
*::before,
*::after {
  box-sizing: border-box;
}

ul[class],
ol[class] {
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
  color: #ffffff;
}

body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  background-color: #000;
  background-attachment: fixed;
}

ul[class],
ol[class] {
  list-style: none;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

article>*+* {
  margin-top: 1em;
}

input,
button,
textarea,
select {
  font: inherit;
}


/* .cards-list {
  width: 90%;
  max-width: 1225px;
} */

@media screen and (max-width: 991px) {
  .cards-list {
    width: 98%;
    max-width: 1225px;
    margin-top: 30px;
    margin-bottom: 40%;
  }
}

@media screen and (min-width: 991px) {
  .cards-list {
    width: 95%;
    max-width: 1225px;
    margin-left: 400px;
    margin-top: 50px;
    margin-bottom: 20%;
  }
}


.background-image {
  position: absolute;
  width: 100vh;
  min-height: 100vh;
  top: 0;
  left: 0;
  overflow: auto;
  max-height: 100vh;
  opacity: 0.5;
  z-index: -1;
}

.justwallet-logo {
  position: absolute;
  max-width: 420px;
  right: 0;
  top: 75px;
}